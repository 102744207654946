@font-face {
  font-family: 'Halebop';
  src: url('../assets/fonts/Halebop-Medium.eot');
  src: url('../assets/fonts/Halebop-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Halebop-Medium.woff2') format('woff2'),
    url('../assets/fonts/Halebop-Medium.woff') format('woff'),
    url('../assets/fonts/Halebop-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halebop';
  src: url('../assets/fonts/Halebop-Light.eot');
  src: url('../assets/fonts/Halebop-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Halebop-Light.woff2') format('woff2'),
    url('../assets/fonts/Halebop-Light.woff') format('woff'),
    url('../assets/fonts/Halebop-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halebop';
  src: url('../assets/fonts/Halebop-XtraBold.eot');
  src: url('../assets/fonts/Halebop-XtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Halebop-XtraBold.woff2') format('woff2'),
    url('../assets/fonts/Halebop-XtraBold.woff') format('woff'),
    url('../assets/fonts/Halebop-XtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --white: #ffffff;
  --SVlight-blue: #4f6ca9;
  --SVblue: #004077;
  --SVteal: #03befa;
  --SVnavy: #051732;
  --SVnavy-light: #152b4b;
  --yellow-button: #fdf237;
  --light-grey-blue: #9ea9bf;
  --deep-red: #a00813;
  --green: #7ad6b1;
  --red: #ff957c;
  --light-grey: #e9e9e9;
  --light-light-grey: #c4cdd5;
  --SVteal-focus: #4fd4ff;
  --SVteal-pale: #b2ecff;
  --pale-blue: #f0fbff;
  --less-pale-blue: #daf3fa;

  --light-green: #d7efe9;
  --dark-green: #9edcdc;
  --gray: #ebeff1;
  --black: #272a2f;

  --yellow: #fdf237;
  --black: #181818;
}
* {
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: white;
  font-family: Halebop;
  overflow-x: hidden;
  // overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  margin: 0;
  padding: 0;
  // overflow: auto;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  font-size: 16px;
  line-height: 18px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
}

.logo {
  position: absolute;
  top: 20px;
  left: 40px;
}
.arrow {
  position: absolute;
  top: 50%;
  left: 20px;
  cursor: pointer;
  opacity: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: var(--yellow-button);
  border: 2px solid black;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 3px 3px 73px 5px rgba(0, 0, 0, 0.5);
  svg {
    transform: rotate(180deg);
    width: 50%;
    height: auto;
  }
  &.right {
    svg {
      transform: rotate(0deg);
    }
    left: unset;
    right: 20px;
  }
}
.bg {
  width: 100%;
  height: 100%;
}
.tex {
  position: absolute;
  top: 20px;
  right: 40px;
  color: var(--black);
}
.btn {
  display: flex;
  background-color: var(--yellow-button);
  box-shadow: 3px 3px 73px 5px rgba(0, 0, 0, 0.5);
  z-index: 3000;
  padding: 10px;
  position: fixed;
  border: 4px solid black;
  bottom: 20px;
  right: 20px;
  color: black;
  align-items: center;
  text-decoration: none;
  svg {
    transform: rotate(90deg);
    margin-right: 10px;
  }
  p {
    font-size: 1.2rem;
    font-weight: 700;
  }
}
.root,
.App {
  display: block;
  position: relative;
  background-color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .picHolder {
    top: 0;
    opacity: 0;
    left: 0;
    position: absolute;
    transform-origin: top left;

    &.show {
      opacity: 1;
    }
    div {
      div {
        img {
          image-rendering: pixelated;
        }
      }
    }
    img {
      width: 100%;
      box-shadow: 3px 3px 73px -12px rgba(0, 0, 0, 0.5);
      pointer-events: none;
    }
  }
  .top {
    width: 100%;
    height: 70px;
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  &.mobile {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    align-items: center;

    flex-direction: column;

    .top {
      z-index: 9000;
      background-color: white;
      &.fixed {
        position: fixed;
      }
    }
    h1 {
      font-size: 1.2rem;
      right: 10px;
      height: 34px;
      display: flex;
      align-items: center;
    }
    .logo {
      left: 20px;
    }
    .css-zvi4ix {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .picHolder {
      margin: auto;
      display: block;
      position: unset;
      opacity: 1;
      width: 90%;
      height: auto;
      transform: translate(0, 20vh);
      transition: all 1s;
      &.show {
        opacity: 1;
        transform: translate(0, 0);
      }
      img {
        //image-rendering: pixelated;
        box-shadow: 3px 3px 12px -1px rgba(0, 0, 0, 0.5);
      }
    }
    .cookieholder {
      .cookie {
        width: 70%;
        max-width: unset;

        .btnholder {
          flex-direction: column;

          .btnCookie {
            margin: unset;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
.row {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  a,
  a:hover,
  a:visited {
    font-weight: 700;
    color: #2ca977;
  }
  b {
    font-size: 1.1rem;
  }
  &.tight {
    margin-bottom: 0px;
  }
  .lihead {
    margin-top: 0;
    font-size: 1.3rem;
    line-height: 1.3rem;
    margin-bottom: 12px;
  }

  &.step1 {
    margin-left: 20px;
  }
  &.step2 {
    margin-left: 40px;
  }
  .texline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .num {
    margin-right: 10px;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  &.small {
    .num {
      font-size: 1.2rem;
      line-height: 1rem;
    }
  }
}
.cookieholder {
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  .cookie {
    position: relative;
    overflow-y: scroll;
    display: flex;
    width: 80%;
    max-width: 55rem;
    max-height: 80%;
    margin: 50px 0px;
    min-height: 10rem;
    background-color: #fff;
    padding: 40px;

    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: var(--black);
    .btnholder {
      width: 100%;
      display: flex;
      flex-direction: row;
      .btnCookie {
        margin: 0px 10px;
        padding: 20px;
        border-width: 2px;
        &:first-child {
          margin-left: 0px;
        }
      }
    }
    &.extra {
      align-items: flex-start;
      justify-content: flex-start;

      text-align: left;
      p {
        margin-bottom: 0px;
      }
      .lihead {
        margin-top: 0px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    h2 {
      margin-bottom: 10px;
      font-size: 2rem;
      line-height: 2rem;
    }
    p {
      margin-bottom: 20px;
    }
    .btnCookie {
      display: flex;
      background-color: var(--yellow-button);
      z-index: 3000;
      padding: 10px 20px;
      border: 2px solid black;
      color: black;
      align-items: center;
      text-decoration: none;
      &.white {
        background-color: #fff;
      }
      svg {
        transform: rotate(90deg);
        margin-right: 10px;
      }
      p {
        font-size: 1.2rem;
        font-weight: 700;
      }
      margin-bottom: 20px;
    }
  }
}
